export const ADD_NETWORK_ERRORS = 'ADD_NETWORK_ERRORS';
export const REMOVE_NETWORK_ERRORS = 'REMOVE_NETWORK_ERRORS';
export const SET_FETCH_NETWORK_STATUS = 'SET_FETCH_NETWORK_STATUS';
export const RECEIVE_NETWORK_DATA = 'RECEIVE_NETWORK_DATA';
export const RECEIVE_NETWORK_BY_CAMPAIGN_ID_DATA =
  'RECEIVE_NETWORK_BY_CAMPAIGN_ID_DATA';
export const RECEIVE_FACEBOOK_NETWORK_DATA = 'RECEIVE_FACEBOOK_NETWORK_DATA';
export const RECEIVE_GOOGLE_NETWORK_DATA = 'RECEIVE_GOOGLE_NETWORK_DATA';
export const SET_FETCH_NETWORK_CAMPAIGN_ID_STATUS =
  'SET_FETCH_NETWORK_CAMPAIGN_ID_STATUS';
export const SET_FETCH_NETWORK_FACEBOOK_STATUS =
  'SET_FETCH_NETWORK_FACEBOOK_STATUS';
export const SET_FETCH_NETWORK_GOOGLE_STATUS =
  'SET_FETCH_NETWORK_GOOGLE_STATUS';
export const SET_FETCH_NETWORK_CAMPAIGN_BY_RANGE_STATUS =
  'SET_FETCH_NETWORK_CAMPAIGN_BY_RANGE_STATUS';
export const RECEIVE_NETWORK_CAMPAIGN_BY_RANGE =
  'RECEIVE_NETWORK_CAMPAIGN_BY_RANGE';
export const SET_FETCH_NETWORK_HOURLY_STATUS =
  'SET_FETCH_NETWORK_HOURLY_STATUS';
export const RECEIVE_NETWORK_HOURLY_DATA = 'RECEIVE_NETWORK_HOURLY_DATA';

export const SET_FETCH_NETWORK_HOURLY_CAMPAIGN_STATUS =
  'SET_FETCH_NETWORK_HOURLY_CAMPAIGN_STATUS';
export const RECEIVE_NETWORK_HOURLY_CAMPAIGN_DATA =
  'RECEIVE_NETWORK_HOURLY_CAMPAIGN_DATA';
