// eslint-disable-next-line import/prefer-default-export
export const role = 'http://efflux.netlify.app/roles';
export const alwaysPresentColumns = [
  'date',
  'name',
  'id',
  'adsetName',
  'adsetId',
];
export const nonSortableColumns = ['id', 'name'];
