import {
  ADD_NETWORK_ERRORS,
  REMOVE_NETWORK_ERRORS,
  SET_FETCH_NETWORK_STATUS,
  RECEIVE_NETWORK_DATA,
  RECEIVE_NETWORK_BY_CAMPAIGN_ID_DATA,
  RECEIVE_FACEBOOK_NETWORK_DATA,
  RECEIVE_GOOGLE_NETWORK_DATA,
  SET_FETCH_NETWORK_FACEBOOK_STATUS,
  SET_FETCH_NETWORK_GOOGLE_STATUS,
  SET_FETCH_NETWORK_CAMPAIGN_ID_STATUS,
  SET_FETCH_NETWORK_CAMPAIGN_BY_RANGE_STATUS,
  RECEIVE_NETWORK_CAMPAIGN_BY_RANGE,
  SET_FETCH_NETWORK_HOURLY_STATUS,
  SET_FETCH_NETWORK_HOURLY_CAMPAIGN_STATUS,
  RECEIVE_NETWORK_HOURLY_DATA,
  RECEIVE_NETWORK_HOURLY_CAMPAIGN_DATA
} from './constants';

const initialState = {
  errors: [],
  fetchNetworkStatus: null,
  fetchNetworkFacebookStatus: null,
  fetchNetworkGoogleStatus: null,
  fetchNetworkCampaignIdStatus: null,
  fetchNetworkCampaignByRangeStatus: null,
  fetchNetworkHourlyStatus: null,
  fetchNetworkHourlyCampaignStatus: null,
  networkDataByMediaBuyer: [],
  networkByCampaignIdData: [],
  networkFacebookData: [],
  networkGoogleData: [],
  networkCampaignByRangeData: [],
  networkHourlyData: [],
  networkHourlyCampaignData: []
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NETWORK_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_NETWORK_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_NETWORK_STATUS: {
      return {
        ...state,
        fetchNetworkStatus: action.payload
      };
    }
    case SET_FETCH_NETWORK_FACEBOOK_STATUS: {
      return {
        ...state,
        fetchNetworkFacebookStatus: action.payload
      };
    }
    case SET_FETCH_NETWORK_GOOGLE_STATUS: {
      return {
        ...state,
        fetchNetworkGoogleStatus: action.payload
      };
    }
    case SET_FETCH_NETWORK_CAMPAIGN_ID_STATUS: {
      return {
        ...state,
        fetchNetworkCampaignIdStatus: action.payload
      };
    }
    case SET_FETCH_NETWORK_CAMPAIGN_BY_RANGE_STATUS: {
      return {
        ...state,
        fetchNetworkCampaignByRangeStatus: action.payload
      };
    }
    case SET_FETCH_NETWORK_HOURLY_STATUS: {
      return {
        ...state,
        fetchNetworkHourlyStatus: action.payload
      };
    }
    case SET_FETCH_NETWORK_HOURLY_CAMPAIGN_STATUS: {
      return {
        ...state,
        fetchNetworkHourlyCampaignStatus: action.payload
      };
    }
    case RECEIVE_NETWORK_DATA: {
      return {
        ...state,
        networkDataByMediaBuyer: action.payload
      };
    }
    case RECEIVE_NETWORK_BY_CAMPAIGN_ID_DATA: {
      return {
        ...state,
        networkByCampaignIdData: action.payload
      };
    }
    case RECEIVE_FACEBOOK_NETWORK_DATA: {
      return {
        ...state,
        networkFacebookData: action.payload
      };
    }
    case RECEIVE_GOOGLE_NETWORK_DATA: {
      return {
        ...state,
        networkGoogleData: action.payload
      };
    }
    case RECEIVE_NETWORK_CAMPAIGN_BY_RANGE: {
      return {
        ...state,
        networkCampaignByRangeData: action.payload
      };
    }
    case RECEIVE_NETWORK_HOURLY_DATA: {
      return {
        ...state,
        networkHourlyData: action.payload
      };
    }
    case RECEIVE_NETWORK_HOURLY_CAMPAIGN_DATA: {
      return {
        ...state,
        networkHourlyCampaignData: action.payload
      };
    }

    default:
      return state;
  }
};

export default analyticsReducer;
