import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setAuthGetter } from './api';

export default function AuthInject() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setAuthGetter(getAccessTokenSilently);
    return () => setAuthGetter(null); // or undefined, as you originally had
  }, [getAccessTokenSilently]);

  return null;
}
