import {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import styled from 'styled-components';

import PrivateRoute from './components/PrivateRoute';
import Loader from './components/core/Loader';
import Toaster from './components/core/Toast';

const Dashboard = lazy(() => {
  return import('./components/dashboard/DashboardPage');
});

const LoginPage = lazy(() => {
  return import('./components/login/LoginPage');
});

const PrivacyPolicy = lazy(() => {
  return import('./components/compliance/PrivacyPolicy');
});

const TermsOfService = lazy(() => {
  return import('./components/compliance/TermsOfService');
});

function App() {
  const {isLoading} = useAuth0();

  const pageLoader = () => {
    return <Loader />;
  };

  return (
    <AppStyle>
      {isLoading ? (
        pageLoader()
      ) : (
        <>
          <Switch>
            <Suspense fallback={pageLoader()}>
              <Route exact path="/" component={LoginPage} />
              <Route
                exact
                path="/terms-of-service"
                component={TermsOfService}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <PrivateRoute component={Dashboard} path="/dashboard" />
            </Suspense>
          </Switch>
          <Toaster />
        </>
      )}
    </AppStyle>
  );
}

const AppStyle = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export default App;
