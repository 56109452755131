import axios from 'axios';

// Define the base URL for axios instance
const baseURL = process.env.REACT_APP_BACKEND_URL;

// Create the axios instance with default headers
export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Token getter variable
let getToken = null;

// Set the token getter function
export const setAuthGetter = (tokenGetter) => {
  getToken = tokenGetter;
};

// Interceptor logic
export const intercept = async (config) => {
  if (!getToken) {
    return config;
  }

  try {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
  } catch (e) {
    console.log(e);
  }
  return config;
};

export default api;

// Add the interceptor to the axios instance
api.interceptors.request.use(intercept);
